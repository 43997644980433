import axios from 'axios'

// config
import baseUrl from '../config'

export const terminateParticipant = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/sef/terminations`,
    data
  })
}

export const bulkTerminateParticipants = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/sef/bulk-terminations`,
    data
  })
}
