import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// config
import { isSef } from 'config/localization'

// utils
import th from '../../utils/themeHelper'

const ContentContainerForDashForm = styled.div`
  position: relative;
  padding: 0;
  width: ${props => (props.emailNeedsConfirming ? '90px 0 0' : '100%')};
  width: 100%;

  @media screen and (min-width: 769px) {
    padding: ${props => (props.emailNeedsConfirming ? '30px 0 0' : 0)};
  }
`

export const FeedbackIconLink = styled(Link)`
  display: block;
  text-decoration: none;
`

export const ContentContainer = styled.div.withConfig({
  shouldForwardProp: prop => !['topPad', 'displayFormBar', 'maxWidth'].includes(prop)
})`
  position: relative;
  padding: 20px 35px 35px;
  padding-right: 15px;
  padding-top: ${({ topPad }) => topPad || '10px'};
  flex: 1 1 auto;
  margin-left: ${({ displayFormBar }) => (displayFormBar ? 'auto' : '0')};
  margin-right: ${({ displayFormBar }) => (displayFormBar ? 'auto' : '0')};
  margin-top: 65px;
  max-width: ${({ maxWidth }) => maxWidth || '1300px'};
  width: 100%;

  @media screen and (max-width: 769px) {
    padding: ${({ topPad }) => (topPad ? '0 15px 20px' : '20px 10px 20px')};
    margin-top: 0;
    width: 100%;
  }
`

export const DashLayoutContentContainer = ({
  children,
  displayFormBar,
  emailNeedsConfirming,
  maxWidth,
  topPad
}) => {
  if (!displayFormBar) {
    return (
      <ContentContainer maxWidth={maxWidth} topPad={topPad}>
        {children}
      </ContentContainer>
    )
  }

  return (
    <ContentContainerForDashForm emailNeedsConfirming={emailNeedsConfirming}>
      {children}
    </ContentContainerForDashForm>
  )
}

export const DashboardContainer = styled.div.withConfig({
  shouldForwardProp: prop => !['isHomePage', 'displayFormBar', 'sidebarIsCollapsed'].includes(prop)
})`
  align-items: flex-start;
  background-color: ${({ isHomePage }) => (isHomePage ? 'white' : th('backgrounds.light'))};
  display: flex;
  flex-wrap: nowrap;
  order: 2;
  padding-left: ${({ displayFormBar, sidebarIsCollapsed }) =>
    displayFormBar ? '0' : sidebarIsCollapsed ? '20px' : isSef ? '140px' : '200px'};
  position: relative;

  @media screen and (min-width: 769px) {
    flex: 1;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding-left: 0;
  }
`

export const DashboardOverWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'visibleModal'
})`
  position: relative;
  height: ${({ visibleModal }) => (visibleModal ? '100vh' : 'initial')};
  overflow: ${({ visibleModal }) => (visibleModal ? 'hidden' : 'scroll')};
`

export const DashboardWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isHomePage'
})`
  background-color: ${({ isHomePage }) => (isHomePage ? 'white' : th('backgrounds.light'))};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const DashMenuButton = styled.div`
  padding: 0 6px;
`

const SiteDownImage = styled.img`
  margin: 0 auto;
  padding: 20px;
  width: 300px;

  @media screen and (min-width: 769px) {
    width: 500px;
  }
`

const SiteDownNote = styled.p`
  font-size: 18px;

  @media screen and (min-width: 769px) {
    margin: 0 auto;
    width: 80%;
  }
`

const SiteDownContainer = styled.div`
  height: 100vh;
  padding: 20px;
  text-align: center;
  width: 100%;
`

export const SiteDownNotice = () => {
  return (
    <SiteDownContainer>
      <h2>We're in "maintenance" mode</h2>

      <SiteDownNote>
        Our servers are undergoing some surprise maintenance. We'll be back up ASAP.
      </SiteDownNote>

      <SiteDownImage src={'https://cdn.browniepoints.africa/assets/bpimg_oxk5nrtlizno4ps.png'} />
    </SiteDownContainer>
  )
}
