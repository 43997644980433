import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const TERMINATE_PARTICIPANT = 'terminations/TERMINATE_PARTICIPANT'
export const TERMINATE_PARTICIPANT_FAILURE = 'terminations/TERMINATE_PARTICIPANT_FAILURE'
export const TERMINATE_PARTICIPANT_SUCCESS = 'terminations/TERMINATE_PARTICIPANT_SUCCESS'
export const BULK_TERMINATE_PARTICIPANTS = 'terminations/BULK_TERMINATE_PARTICIPANTS'
export const BULK_TERMINATE_PARTICIPANTS_FAILURE =
  'terminations/BULK_TERMINATE_PARTICIPANTS_FAILURE'
export const BULK_TERMINATE_PARTICIPANTS_SUCCESS =
  'terminations/BULK_TERMINATE_PARTICIPANTS_SUCCESS'

export const {
  terminations: {
    terminateParticipant,
    terminateParticipantSuccess,
    terminateParticipantFailure,
    bulkTerminateParticipants,
    bulkTerminateParticipantsSuccess,
    bulkTerminateParticipantsFailure
  }
} = createActions({
  [TERMINATE_PARTICIPANT]: undefined,
  [TERMINATE_PARTICIPANT_FAILURE]: undefined,
  [TERMINATE_PARTICIPANT_SUCCESS]: undefined,
  [BULK_TERMINATE_PARTICIPANTS]: undefined,
  [BULK_TERMINATE_PARTICIPANTS_FAILURE]: undefined,
  [BULK_TERMINATE_PARTICIPANTS_SUCCESS]: undefined
})

const initialState = {
  data: null,
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isSuccess: null
}

export default handleActions(
  {
    [TERMINATE_PARTICIPANT]: startLoading,
    [TERMINATE_PARTICIPANT_FAILURE]: loadingError,
    [TERMINATE_PARTICIPANT_SUCCESS]: state => ({
      ...state,
      hasError: false,
      isLoading: false,
      isSuccess: true
    }),
    [BULK_TERMINATE_PARTICIPANTS]: startLoading,
    [BULK_TERMINATE_PARTICIPANTS_FAILURE]: loadingError,
    [BULK_TERMINATE_PARTICIPANTS_SUCCESS]: state => ({
      ...state,
      hasError: false,
      isLoading: false,
      isSuccess: true
    })
  },
  initialState
)
