import { combineReducers } from 'redux'

import accountActivity from './accountActivity'
import applications from './applications'
import attendance from './attendance'
import authentication from './authentication'
import boosts from './boosts'
import campaigns from './campaigns'
import campaignPartners from './campaignPartners'
import categories from './categories'
import donations from './donations'
import donationConfirmations from './donationConfirmations'
import donationSubscriptions from './donationSubscriptions'
import emailActivity from './emailActivity'
import faqs from './faqs'
import feedback from './feedback'
import followings from './followings'
import instagramBioLinks from './instagramBioLinks'
import messaging from './messaging'
import notifications from './notifications'
import opportunities from './opportunities'
import organisations from './organisations'
import ozow from './ozow'
import payments from './payments'
import people from './people'
import personalGoals from './personalGoals'
import platformEvents from './platformEvents'
import platformSubscriptions from './platformSubscriptions'
import places from './places'
import search from './search'
import stats from './stats'
import stories from './stories'
import teams from './teams'
import teamGroups from './teamGroups'
import terminations from './terminations'

//
// Dgmt sagas
//
import dgmt from './dgmt'

//
// Sef sagas
//
import sef from './sef'

export default combineReducers({
  accountActivity,
  applications,
  attendance,
  authentication,
  boosts,
  campaigns,
  campaignPartners,
  categories,
  donations,
  donationConfirmations,
  donationSubscriptions,
  emailActivity,
  faqs,
  feedback,
  followings,
  instagramBioLinks,
  messaging,
  notifications,
  opportunities,
  organisations,
  ozow,
  payments,
  people,
  places,
  personalGoals,
  platformEvents,
  platformSubscriptions,
  search,
  stats,
  stories,
  teams,
  teamGroups,
  terminations,
  //
  // dgmt sagas
  //
  dgmt,
  //
  // sef sagas
  //
  sef
})
