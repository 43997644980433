import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const FETCH_MEMBERS = 'people/FETCH_MEMBERS'
export const FETCH_MEMBERS_SUCCESS = 'people/FETCH_MEMBERS_SUCCESS'
export const FETCH_MEMBERS_FAILURE = 'people/FETCH_MEMBERS_FAILURE'

export const FETCH_MEMBER = 'people/FETCH_MEMBER'
export const FETCH_MEMBER_SUCCESS = 'people/FETCH_MEMBER_SUCCESS'
export const FETCH_MEMBER_FAILURE = 'people/FETCH_MEMBER_FAILURE'

export const FETCH_PARTICIPANT = 'people/FETCH_PARTICIPANT'
export const FETCH_PARTICIPANT_SUCCESS = 'people/FETCH_PARTICIPANT_SUCCESS'
export const FETCH_PARTICIPANT_FAILURE = 'people/FETCH_PARTICIPANT_FAILURE'

export const FETCH_PUBLIC_USER = 'people/FETCH_PUBLIC_USER'
export const FETCH_PUBLIC_USER_SUCCESS = 'people/FETCH_PUBLIC_USER_SUCCESS'
export const FETCH_PUBLIC_USER_FAILURE = 'people/FETCH_PUBLIC_USER_FAILURE'

export const CHECK_ROLE = 'people/CHECK_ROLE'
export const CHECK_ROLE_SUCCESS = 'people/CHECK_ROLE_SUCCESS'
export const CHECK_ROLE_FAILURE = 'people/CHECK_ROLE_FAILURE'

export const UPDATE_USER_STORIES = 'people/UPDATE_USER_STORIES'

export const UPDATE_USER = 'people/UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'people/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'people/UPDATE_USER_FAILURE'

export const UPDATE_USER_AS_GOD_ADMIN = 'people/UPDATE_USER_AS_GOD_ADMIN'
export const UPDATE_USER_AS_GOD_ADMIN_SUCCESS = 'people/UPDATE_USER_AS_GOD_ADMIN_SUCCESS'
export const UPDATE_USER_AS_GOD_ADMIN_FAILURE = 'people/UPDATE_USER_AS_GOD_ADMIN_FAILURE'

export const DELETE_MEMBER = 'people/DELETE_MEMBER'
export const DELETE_SUCCESS = 'people/DELETE_SUCCESS'
export const DELETE_FAILURE = 'people/DELETE_FAILURE'

export const REMOVE_PREFERRED_CATEGORY = 'people/REMOVE_PREFERRED_CATEGORY'
export const REMOVE_PREFERRED_CATEGORY_SUCCESS = 'people/REMOVE_PREFERRED_CATEGORY_SUCCESS'
export const REMOVE_PREFERRED_CATEGORY_FAILURE = 'people/REMOVE_PREFERRED_CATEGORY_FAILURE'

export const SEND_INVITATION = 'people/SEND_INVITATION'
export const SEND_SUCCESS = 'people/SEND_SUCCESS'
export const SEND_FAILURE = 'people/SEND_FAILURE'

export const TOGGLE_SEF_BIO_FEATURE = 'people/TOGGLE_SEF_BIO_FEATURE'

export const FETCH_EMPLOYMENT_AGREEMENT = 'people/FETCH_EMPLOYMENT_AGREEMENT'
export const FETCH_EMPLOYMENT_AGREEMENT_SUCCESS = 'people/FETCH_EMPLOYMENT_AGREEMENT_SUCCESS'
export const FETCH_EMPLOYMENT_AGREEMENT_FAILURE = 'people/FETCH_EMPLOYMENT_AGREEMENT_FAILURE'

export const UPDATE_EMPLOYMENT_AGREEMENT = 'people/UPDATE_EMPLOYMENT_AGREEMENT'
export const UPDATE_EMPLOYMENT_AGREEMENT_SUCCESS = 'people/UPDATE_EMPLOYMENT_AGREEMENT_SUCCESS'
export const UPDATE_EMPLOYMENT_AGREEMENT_FAILURE = 'people/UPDATE_EMPLOYMENT_AGREEMENT_FAILURE'

export const RESET_PEOPLE_STATE = 'people/RESET_PEOPLE_STATE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  people: {
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailure,

    fetchMember,
    fetchMemberSuccess,
    fetchMemberFailure,

    fetchParticipant,
    fetchParticipantSuccess,
    fetchParticipantFailure,

    fetchPublicUser,
    fetchPublicUserSuccess,
    fetchPublicUserFailure,

    checkRole,
    checkRoleSuccess,
    checkRoleFailure,

    updateUserStories,
    updateUser,
    updateUserSuccess,
    updateUserFailure,

    updateUserAsGodAdmin,
    updateUserAsGodAdminSuccess,
    updateUserAsGodAdminFailure,

    deleteMember,
    deleteSuccess,
    deleteFailure,

    removePreferredCategory,
    removePreferredCategorySuccess,
    removePreferredCategoryFailure,

    sendInvitation,
    sendSuccess,
    sendFailure,

    toggleSefBioFeature,

    fetchEmploymentAgreement,
    fetchEmploymentAgreementFailure,
    fetchEmploymentAgreementSuccess,

    updateEmploymentAgreement,
    updateEmploymentAgreementFailure,
    updateEmploymentAgreementSuccess,

    resetPeopleState
  }
} = createActions({
  [FETCH_MEMBERS]: undefined,
  [FETCH_MEMBERS_SUCCESS]: undefined,
  [FETCH_MEMBERS_FAILURE]: undefined,

  [FETCH_MEMBER]: undefined,
  [FETCH_MEMBER_SUCCESS]: undefined,
  [FETCH_MEMBER_FAILURE]: undefined,

  [FETCH_PARTICIPANT]: undefined,
  [FETCH_PARTICIPANT_SUCCESS]: undefined,
  [FETCH_PARTICIPANT_FAILURE]: undefined,

  [FETCH_PUBLIC_USER]: undefined,
  [FETCH_PUBLIC_USER_SUCCESS]: undefined,
  [FETCH_PUBLIC_USER_FAILURE]: undefined,

  [CHECK_ROLE]: undefined,
  [CHECK_ROLE_SUCCESS]: undefined,
  [CHECK_ROLE_FAILURE]: undefined,

  [UPDATE_USER_STORIES]: undefined,
  [UPDATE_USER]: undefined,
  [UPDATE_USER_SUCCESS]: undefined,
  [UPDATE_USER_FAILURE]: undefined,

  [UPDATE_USER_AS_GOD_ADMIN]: undefined,
  [UPDATE_USER_AS_GOD_ADMIN_SUCCESS]: undefined,
  [UPDATE_USER_AS_GOD_ADMIN_FAILURE]: undefined,

  [DELETE_MEMBER]: undefined,
  [DELETE_SUCCESS]: deletedMemberId => deletedMemberId,
  [DELETE_FAILURE]: undefined,

  [REMOVE_PREFERRED_CATEGORY]: undefined,
  [REMOVE_PREFERRED_CATEGORY_SUCCESS]: undefined,
  [REMOVE_PREFERRED_CATEGORY_FAILURE]: undefined,

  [SEND_INVITATION]: undefined,
  [SEND_SUCCESS]: undefined,
  [SEND_FAILURE]: undefined,

  [TOGGLE_SEF_BIO_FEATURE]: undefined,
  [RESET_PEOPLE_STATE]: undefined,

  [FETCH_EMPLOYMENT_AGREEMENT]: undefined,
  [FETCH_EMPLOYMENT_AGREEMENT_SUCCESS]: undefined,
  [FETCH_EMPLOYMENT_AGREEMENT_FAILURE]: undefined,

  [UPDATE_EMPLOYMENT_AGREEMENT]: undefined,
  [UPDATE_EMPLOYMENT_AGREEMENT_SUCCESS]: undefined,
  [UPDATE_EMPLOYMENT_AGREEMENT_FAILURE]: undefined
})

const initialPaginatedData = {
  pageCount: 0,
  checkInsCount: 0,
  applicationsCount: 0,
  memberCount: 0,
  partnerInviteToken: 0,
  teamsCount: 0,
  uniqueOpportunitiesJoined: 0
}

const initialState = {
  isLoading: null,
  isSubmitting: false,
  isSuccess: null,
  hasError: false,
  errorMessage: null,
  singleEmploymentAgreement: {},
  employmentAgreementUpdateSuccess: null,
  singlePersonData: {},
  singleParticipantData: {},
  data: [],
  paginatedPeopleData: initialPaginatedData
}

export default handleActions(
  {
    [FETCH_MEMBERS]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_MEMBERS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        employmentAgreementUpdateSuccess: null,
        data: payload.teamMembers
      }

      newState.partnerInviteToken = payload.partnerInviteToken

      newState.paginatedPeopleData.pageCount = payload.pageCount || 0
      newState.paginatedPeopleData.memberCount = payload.memberCount || 0
      newState.paginatedPeopleData.teamsCount = payload.teamsCount || 0
      newState.paginatedPeopleData.uniqueOpportunitiesJoined =
        payload.uniqueOpportunitiesJoined || 0
      newState.paginatedPeopleData.applicationsCount = payload.applicationsCount || 0
      newState.paginatedPeopleData.checkInsCount = payload.checkInsCount || 0

      return newState
    },
    [FETCH_MEMBERS_FAILURE]: (state, { payload }) => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),

    [FETCH_MEMBER]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_MEMBER_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      singlePersonData: payload
    }),
    [FETCH_MEMBER_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),

    [FETCH_PARTICIPANT]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_PARTICIPANT_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      singleParticipantData: payload
    }),
    [FETCH_PARTICIPANT_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message,
      singleParticipantData: null
    }),

    [FETCH_PUBLIC_USER]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_PUBLIC_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      singlePersonData: payload
    }),
    [FETCH_PUBLIC_USER_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),
    [CHECK_ROLE]: state => ({ ...state, isLoading: true }),
    [CHECK_ROLE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      rolesData: payload
    }),
    [CHECK_ROLE_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),

    [UPDATE_USER_STORIES]: (state, { payload }) => {
      const { singlePersonData } = state
      const stories = singlePersonData.stories || []
      if (!stories.length) return state

      const newPersonData = {
        ...singlePersonData,
        stories: stories.map(s => {
          if (s.id === payload.id) {
            const oneMore = (s.story_likes || 0) + 1
            const oneLess = (s.story_likes || 1) - 1
            const userLikesStory = payload.value === 'liked'
            const newLikesCount = userLikesStory ? oneMore : oneLess

            return { ...s, story_likes: newLikesCount, user_likes_story: userLikesStory }
          }

          return s
        })
      }

      return { ...state, singlePersonData: newPersonData }
    },

    [UPDATE_USER]: state => ({ ...state, isSubmitting: true }),
    [UPDATE_USER_SUCCESS]: (state, { payload }) => {
      UrlBuilder.displayUpdateSuccessMessage()

      return { ...state, isLoading: false, isSubmitting: false, singlePersonData: payload.user }
    },
    [UPDATE_USER_FAILURE]: state => ({ ...state, isLoading: false }),
    [UPDATE_USER_AS_GOD_ADMIN]: state => ({ ...state, isSubmitting: true }),
    [UPDATE_USER_AS_GOD_ADMIN_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [UPDATE_USER_AS_GOD_ADMIN_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_MEMBER]: state => ({ ...state, isSubmitting: true }),
    [DELETE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      data: state.data.filter(o => o.id !== parseInt(payload.deletedMemberId))
    }),
    [DELETE_FAILURE]: state => ({ ...state, isLoading: false }),
    [REMOVE_PREFERRED_CATEGORY]: state => ({ ...state, isSubmitting: true, isLoading: true }),
    [REMOVE_PREFERRED_CATEGORY_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return { ...state, isSubmitting: false, isLoading: false, hasError: false }
    },
    [REMOVE_PREFERRED_CATEGORY_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      isLoading: false,
      hasError: true
    }),
    [SEND_INVITATION]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [SEND_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return {
        ...state,
        hasError: false,
        errorMessage: null,
        isLoading: false,
        isSubmitting: false,
        data: payload
      }
    },
    [SEND_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [RESET_PEOPLE_STATE]: state => ({ ...state, employmentAgreementUpdateSuccess: null }),
    [TOGGLE_SEF_BIO_FEATURE]: (state, { payload }) => {
      const membersData = state.data.map(member => {
        if (member.id === payload) {
          return { ...member, is_featured: !member.is_featured }
        }

        return member
      })

      return { ...state, data: membersData }
    },
    [FETCH_EMPLOYMENT_AGREEMENT]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_EMPLOYMENT_AGREEMENT_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      singleEmploymentAgreement: payload
    }),
    [FETCH_EMPLOYMENT_AGREEMENT_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),
    [UPDATE_EMPLOYMENT_AGREEMENT]: state => ({
      ...state,
      employmentAgreementUpdateSuccess: null,
      isSuccess: null,
      isSubmitting: true
    }),
    [UPDATE_EMPLOYMENT_AGREEMENT_SUCCESS]: state => ({
      ...state,
      isSubmitting: false,
      isSuccess: true,
      employmentAgreementUpdateSuccess: true
    }),
    [UPDATE_EMPLOYMENT_AGREEMENT_FAILURE]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      isSuccess: false,
      employmentAgreementUpdateSuccess: false,
      hasError: true,
      errorMessage: payload.message
    })
  },
  initialState
)
