import { call, put, takeLatest } from 'redux-saga/effects'
import * as attendanceApi from '../apiModules/attendance'
import * as attendanceModule from '../modules/attendance'

function* fetchParticipantAttendance({ payload }) {
  try {
    const response = yield call(attendanceApi.fetchParticipantAttendance, payload)
    const result = { ...response, forceRefresh: payload.forceRefresh }
    yield put(attendanceModule.fetchParticipantAttendanceSuccess(result))
  } catch (err) {
    yield put(attendanceModule.fetchParticipantAttendanceFailure(err.response.data))
  }
}

function* fetchParticipantAttendances({ payload }) {
  try {
    const response = yield call(attendanceApi.fetchParticipantAttendances, payload)
    yield put(attendanceModule.fetchParticipantAttendancesSuccess(response))
  } catch (err) {
    yield put(attendanceModule.fetchParticipantAttendancesFailure(err.response.data))
  }
}

function* createParticipantAttendance({ payload }) {
  try {
    const createdAttendanceLog = yield call(attendanceApi.createParticipantAttendance, payload)
    yield put(attendanceModule.createParticipantAttendanceSuccess(createdAttendanceLog))
  } catch (err) {
    yield put(attendanceModule.createParticipantAttendanceFailure(err.response.data))
  }
}

function* updateParticipantAttendance({ payload }) {
  try {
    yield call(attendanceApi.updateParticipantAttendance, payload)
    yield put(attendanceModule.updateParticipantAttendanceSuccess(payload))
  } catch (err) {
    yield put(attendanceModule.updateParticipantAttendanceFailure(err.response.data))
  }
}

export const attendanceSagas = [
  takeLatest(attendanceModule.FETCH_PARTICIPANT_ATTENDANCE, fetchParticipantAttendance),
  takeLatest(attendanceModule.FETCH_PARTICIPANT_ATTENDANCES, fetchParticipantAttendances),
  takeLatest(attendanceModule.CREATE_PARTICIPANT_ATTENDANCE, createParticipantAttendance),
  takeLatest(attendanceModule.UPDATE_PARTICIPANT_ATTENDANCE, updateParticipantAttendance)
]
