import { call, put, takeLeading, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import { parse } from 'qs'

import * as authApi from '../apiModules/authentication'
import * as peopleApi from '../apiModules/people'
import * as sefApi from '../apiModules/sef'

import * as authenticationModule from '../modules/authentication'
import * as peopleModule from '../modules/people'

// utils
import { updateLocalStorageValues } from 'utils/localStorageManager'
import { isSef } from 'config/localization'

function* sendInvitation({ payload }) {
  try {
    const response = yield call(peopleApi.sendInvitation, payload)
    yield put(peopleModule.sendSuccess(response))
  } catch (err) {
    if (err.response.status === 403) {
      yield put(
        peopleModule.sendFailure({
          errorMessage: 'You are not permitted to do that.',
          hasError: true
        })
      )
    } else {
      yield put(peopleModule.sendFailure(err))
    }
  }
}

function* fetchMember({ payload }) {
  try {
    const response = yield call(peopleApi.fetchMember, payload)
    yield put(peopleModule.fetchMemberSuccess(response))
  } catch (err) {
    yield put(peopleModule.fetchMemberFailure(err))
  }
}

function* fetchParticipant({ payload }) {
  try {
    const response = yield call(peopleApi.fetchParticipant, payload)
    yield put(peopleModule.fetchParticipantSuccess(response))
  } catch (err) {
    yield put(peopleModule.fetchParticipantFailure(err.response.data))
  }
}

function* fetchPublicUser({ payload }) {
  try {
    const response = yield call(peopleApi.fetchPublicUser, payload)
    yield put(peopleModule.fetchPublicUserSuccess(response))
  } catch (err) {
    yield put(peopleModule.fetchPublicUserFailure(err))
  }
}

function* fetchMembers({ payload }) {
  try {
    const response = yield call(peopleApi.fetchMembers, payload)
    yield put(peopleModule.fetchMembersSuccess(response))
  } catch (err) {
    yield put(peopleModule.fetchMembersFailure(err))
  }
}

function* checkRole({ payload }) {
  try {
    const response = yield call(peopleApi.checkRole, payload)
    yield put(peopleModule.checkRoleSuccess(response))
  } catch (err) {
    yield put(peopleModule.checkRoleFailure(err.response.data))
  }
}

function* deleteMember({ payload }) {
  try {
    const response = yield call(peopleApi.deleteMember, payload)
    yield put(peopleModule.deleteSuccess(response.data))
    return navigate('/dashboard/people')
  } catch (err) {
    yield put(peopleModule.deleteFailure(err))
  }
}

function* removePreferredCategory({ payload }) {
  try {
    const response = yield call(peopleApi.removePreferredCategory, payload)
    yield put(peopleModule.removePreferredCategorySuccess(response.data))

    // re-fetch roles for user to update details. Needs refactor
    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))

    // Append hash to window location so the page can scroll back down to the correct area
    window.location.hash = 'categories'
  } catch (err) {
    yield put(peopleModule.removePreferredCategoryFailure(err))
  }
}

function* updateUserAsGodAdmin({ payload }) {
  try {
    yield call(peopleApi.updateUserAsGodAdmin, payload)
    yield put(peopleModule.updateUserAsGodAdminSuccess())

    if (isSef) {
      return navigate('/dashboard/participants')
    }

    return navigate('/dashboard/people')
  } catch (err) {
    yield put(peopleModule.updateUserAsGodAdminFailure(err))
  }
}

function* updateUser({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const response = yield call(peopleApi.updateUser, formDataVals)
    yield put(peopleModule.updateUserSuccess(response))
    yield put(authenticationModule.fetchMyRoles({ includeCustomFields: true }))

    yield updateLocalStorageValues({
      id: response.user.id,
      email: response.user.email,
      username: response.user.username,
      mobile: response.user.mobile,
      avatar_url: response.user.avatar_url,
      f_name: response.user.f_name,
      l_name: response.user.l_name,
      location: response.user.location,
      preferredCategoryIds: response.user.preferredCategoryIds,
      orgDetails: response.orgDetails
    })

    if (payload.has_attached_image) {
      // users that change their avatar, and then make subsequent updates
      // will have very slow PUT requests because the API keeps processing
      // the attached image over and over. Need to clear the attachment after
      // the first successful image change update. Do that in DashboardAccount.js
      // but for now just reload the page.
      window.location.reload()
    }

    if (payload.is_onboarding) {
      navigate('/onboarding')
    }
  } catch (err) {
    yield put(peopleModule.updateUserFailure(err))
  }
}

function* toggleSefBioFeature({ payload }) {
  yield call(sefApi.toggleSefBioFeature, payload)
  yield put(peopleModule.toggleSefBioFeature({ payload }))
}

function* resetPeopleState() {
  yield put(peopleModule.resetPeopleState())
}

function* fetchEmploymentAgreement({ payload }) {
  try {
    const response = yield call(sefApi.fetchEmploymentAgreement, payload)
    yield put(peopleModule.fetchEmploymentAgreementSuccess(response))
  } catch (err) {
    yield put(peopleModule.fetchEmploymentAgreementFailure(err))
  }
}

function* updateEmploymentAgreement({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (value !== 'navigate' && payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    yield call(sefApi.updateEmploymentAgreement, formDataVals)
    yield put(peopleModule.updateEmploymentAgreement({ payload }))
    yield put(peopleModule.updateEmploymentAgreementSuccess())

    if (payload.refetchPeople) {
      const { agreements, oid, page, perPage, role, s, worksiteId, workspaceId } = parse(
        location.search.replace('?', '')
      )

      const response = yield call(peopleApi.fetchMembers, {
        agreements,
        oid,
        page,
        perPage,
        role,
        s,
        scope: 'participants',
        worksiteId,
        workspaceId
      })
      yield put(peopleModule.fetchMembersSuccess(response))

      payload.navigate('/dashboard/participants')
    }
  } catch (err) {
    yield put(peopleModule.updateEmploymentAgreementFailure(err.response.data))
  }
}

export const peopleSagas = [
  takeLatest(peopleModule.SEND_INVITATION, sendInvitation),
  takeLatest(peopleModule.FETCH_MEMBER, fetchMember),
  takeLatest(peopleModule.FETCH_PARTICIPANT, fetchParticipant),
  takeLatest(peopleModule.FETCH_PUBLIC_USER, fetchPublicUser),
  takeLatest(peopleModule.FETCH_MEMBERS, fetchMembers),
  takeLatest(peopleModule.CHECK_ROLE, checkRole),
  takeLatest(peopleModule.DELETE_MEMBER, deleteMember),
  takeLatest(peopleModule.REMOVE_PREFERRED_CATEGORY, removePreferredCategory),
  takeLatest(peopleModule.UPDATE_USER, updateUser),
  takeLatest(peopleModule.UPDATE_USER_AS_GOD_ADMIN, updateUserAsGodAdmin),
  takeLeading(peopleModule.TOGGLE_SEF_BIO_FEATURE, toggleSefBioFeature),
  takeLeading(peopleModule.FETCH_EMPLOYMENT_AGREEMENT, fetchEmploymentAgreement),
  takeLeading(peopleModule.UPDATE_EMPLOYMENT_AGREEMENT, updateEmploymentAgreement),
  takeLeading(peopleModule.RESET_PEOPLE_STATE, resetPeopleState)
]
