import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading, startSubmitting } from './_helpers'

export const FETCH_PARTICIPANT_ATTENDANCE = 'attendance/FETCH_PARTICIPANT_ATTENDANCE'
export const FETCH_PARTICIPANT_ATTENDANCE_FAILURE =
  'attendance/FETCH_PARTICIPANT_ATTENDANCE_FAILURE'
export const FETCH_PARTICIPANT_ATTENDANCE_SUCCESS =
  'attendance/FETCH_PARTICIPANT_ATTENDANCE_SUCCESS'

export const FETCH_PARTICIPANT_ATTENDANCES = 'attendance/FETCH_PARTICIPANT_ATTENDANCES'
export const FETCH_PARTICIPANT_ATTENDANCES_FAILURE =
  'attendance/FETCH_PARTICIPANT_ATTENDANCES_FAILURE'
export const FETCH_PARTICIPANT_ATTENDANCES_SUCCESS =
  'attendance/FETCH_PARTICIPANT_ATTENDANCES_SUCCESS'

export const CREATE_PARTICIPANT_ATTENDANCE = 'attendance/CREATE_PARTICIPANT_ATTENDANCE'
export const CREATE_PARTICIPANT_ATTENDANCE_FAILURE =
  'attendance/CREATE_PARTICIPANT_ATTENDANCE_FAILURE'
export const CREATE_PARTICIPANT_ATTENDANCE_SUCCESS =
  'attendance/CREATE_PARTICIPANT_ATTENDANCE_SUCCESS'

export const UPDATE_PARTICIPANT_ATTENDANCE = 'attendance/UPDATE_PARTICIPANT_ATTENDANCE'
export const UPDATE_PARTICIPANT_ATTENDANCE_FAILURE =
  'attendance/UPDATE_PARTICIPANT_ATTENDANCE_FAILURE'
export const UPDATE_PARTICIPANT_ATTENDANCE_SUCCESS =
  'attendance/UPDATE_PARTICIPANT_ATTENDANCE_SUCCESS'

export const {
  attendance: {
    fetchParticipantAttendance,
    fetchParticipantAttendanceSuccess,
    fetchParticipantAttendanceFailure,
    fetchParticipantAttendances,
    fetchParticipantAttendancesSuccess,
    fetchParticipantAttendancesFailure,
    createParticipantAttendance,
    createParticipantAttendanceSuccess,
    createParticipantAttendanceFailure,
    updateParticipantAttendance,
    updateParticipantAttendanceSuccess,
    updateParticipantAttendanceFailure
  }
} = createActions({
  [FETCH_PARTICIPANT_ATTENDANCE]: undefined,
  [FETCH_PARTICIPANT_ATTENDANCE_FAILURE]: undefined,
  [FETCH_PARTICIPANT_ATTENDANCE_SUCCESS]: undefined,
  [FETCH_PARTICIPANT_ATTENDANCES]: undefined,
  [FETCH_PARTICIPANT_ATTENDANCES_FAILURE]: undefined,
  [FETCH_PARTICIPANT_ATTENDANCES_SUCCESS]: undefined,
  [CREATE_PARTICIPANT_ATTENDANCE]: undefined,
  [CREATE_PARTICIPANT_ATTENDANCE_FAILURE]: undefined,
  [CREATE_PARTICIPANT_ATTENDANCE_SUCCESS]: undefined,
  [UPDATE_PARTICIPANT_ATTENDANCE]: undefined,
  [UPDATE_PARTICIPANT_ATTENDANCE_FAILURE]: undefined,
  [UPDATE_PARTICIPANT_ATTENDANCE_SUCCESS]: undefined
})

const initialState = {
  data: null,
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isSubmitting: false,
  isSuccess: null,
  paginatedAttendancesData: { attendances: [], attendancesCount: 0, attendancesPageCount: 0 }
}

export default handleActions(
  {
    [FETCH_PARTICIPANT_ATTENDANCE]: startLoading,
    [FETCH_PARTICIPANT_ATTENDANCE_FAILURE]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.message || 'An unknown error occurred',
      isLoading: false,
      isSuccess: false
    }),
    [FETCH_PARTICIPANT_ATTENDANCE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      isSuccess: payload.forceRefresh ? true : null,
      hasError: false,
      data: payload.forceRefresh ? state.data : payload
    }),
    [FETCH_PARTICIPANT_ATTENDANCES]: startLoading,
    [FETCH_PARTICIPANT_ATTENDANCES_FAILURE]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.message || 'An unknown error occurred',
      isLoading: false,
      isSuccess: false
    }),
    [FETCH_PARTICIPANT_ATTENDANCES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedAttendancesData.attendancesPageCount =
        payload.attendancesPageCount || payload.pageCount || 0
      newState.paginatedAttendancesData.attendancesCount = payload.attendancesCount || 0
      newState.paginatedAttendancesData.attendances = payload.attendances || []

      return newState
    },
    [CREATE_PARTICIPANT_ATTENDANCE]: startSubmitting,
    [CREATE_PARTICIPANT_ATTENDANCE_FAILURE]: loadingError,
    [CREATE_PARTICIPANT_ATTENDANCE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      hasSubmitted: true,
      isSuccess: true,
      hasError: false,
      data: {
        ...state.data,
        attendance: [...state.data.attendance, payload.data]
      }
    }),
    [UPDATE_PARTICIPANT_ATTENDANCE]: startSubmitting,
    [UPDATE_PARTICIPANT_ATTENDANCE_FAILURE]: loadingError,
    [UPDATE_PARTICIPANT_ATTENDANCE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        hasSubmitted: true,
        isSuccess: true,
        hasError: false,
        data: {
          ...state.data,
          attendance: state.data.attendance.map(attendance => {
            if (attendance.id === payload.attendanceLogId) {
              return { ...attendance, ...payload.data }
            }
            return attendance
          })
        }
      }
    }
  },
  initialState
)
