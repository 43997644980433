import { call, put, takeLatest } from 'redux-saga/effects'
import * as terminationsApi from '../apiModules/terminations'
import * as terminationsModule from '../modules/terminations'

function* terminateParticipant({ payload }) {
  try {
    yield call(terminationsApi.terminateParticipant, payload)
    yield put(terminationsModule.terminateParticipantSuccess())
  } catch (err) {
    yield put(terminationsModule.terminateParticipantFailure(err.response.data))
  }
}

function* bulkTerminateParticipants({ payload }) {
  try {
    yield call(terminationsApi.bulkTerminateParticipants, payload)
    yield put(terminationsModule.bulkTerminateParticipantsSuccess())
  } catch (err) {
    yield put(terminationsModule.bulkTerminateParticipantsFailure(err.response.data))
  }
}

export const terminationsSagas = [
  takeLatest(terminationsModule.TERMINATE_PARTICIPANT, terminateParticipant),
  takeLatest(terminationsModule.BULK_TERMINATE_PARTICIPANTS, bulkTerminateParticipants)
]
